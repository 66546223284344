import type { WritableSignal } from '@angular/core';
import { ChangeDetectionStrategy, Component, signal } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { StepsModule } from 'primeng/steps';

import { StoreService } from '../../services/store.service';

import type { MenuItem } from 'primeng/api';

type StepItem = MenuItem & { i18nKey: string };

@Component({
  selector: 'app-steps',
  standalone: true,
  imports: [StepsModule],
  templateUrl: './steps.component.html',
  styleUrls: ['./steps.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StepsComponent {
  protected step = this._storeService.step;
  protected steps: WritableSignal<StepItem[]> = signal([
    {
      i18nKey: 'steps.first',
      label: this._translateService.instant('steps.first'),
    },
    {
      i18nKey: 'steps.second',
      label: this._translateService.instant('steps.second'),
    },
    {
      i18nKey: 'steps.third',
      label: this._translateService.instant('steps.third'),
    },
  ]);

  public constructor(
    private readonly _storeService: StoreService,
    private readonly _translateService: TranslateService,
  ) {
    this._translateService.onLangChange.subscribe(() => {
      this.steps.update((steps) =>
        steps.map((s) => ({
          ...s,
          label: this._translateService.instant(s.i18nKey),
        })),
      );
    });
  }
}
